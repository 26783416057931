import autosize from "autosize";

require("@popperjs/core");

import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
//import 'bootstrap/js/dist/modal';
//import 'bootstrap/js/dist/offcanvas';
//import 'bootstrap/js/dist/popover';
//import 'bootstrap/js/dist/scrollspy';
import "bootstrap/js/dist/tab";
//import 'bootstrap/js/dist/toast';
//import 'bootstrap/js/dist/tooltip';
import { default as Tooltip } from "bootstrap/js/dist/tooltip";

require("./app/ab-tests.js");
require("./app/add-reference.js");
require("./app/badges.js");
require("./app/dialogs.js");
require("./app/document/document-add.js");
require("./app/document/document-delete.js");
require("./app/homepage/profile-research-opt-in.js");
require("./app/conversation/booking-tooltip.js");
require("./app/conversation/canned-responses.js");
require("./app/conversation/freemium-unlock");
require("./app/conversation/textarea-cookie-value.js");
require("./app/conversation/conversation_read_time.js");
require("./app/conversation/conversation_unlock.js");
require("./app/conversation/message-list.js");
require("./app/conversation/messaging.js");
require("./app/phone_sms_verification.js");

require("./app/booking/booking-addon-time.js");
require("./app/booking/booking-collection.js");
require("./app/booking/booking-create.js");
require("./app/booking/booking-resource.js");
require("./app/booking/conversation_ready_to_book.js");

require("./app/components/go_to_url.js");
require("./app/components/next_steps.js");

require("./app/cookie-consent-banner.js");

require("./app/profile-map.js");

require("./app/account/account.js");
require("./app/account/payments_payouts.js");
require("./app/account/notification_updates.js");

require("./app/lottie-animations.js");
require("./app/reference-invite.js");

require("./app/search/double-slider.js");
require("./app/search/search.js");
require("./app/search/saved-search.js");
require("./app/search/special-needs.js");
require("./app/share-your-experience.js");
require("./app/subnav.js");
require("./app/profile.js");
require("./app/profile/profile-contact.js");
require("./app/profile-photo.js");
require("./app/profile/freemium-contact");
require("./app/profile/profile-contact-reminder.js");
require("./app/profile/profile-dialog-freemium.js");
require("./app/profile/profile-translate.js");
require("./app/profile/profile-description.js");
require("./app/profile-edit/profile-video.js");
require("./app/profile-edit/availability.js");
require("./app/profile-edit/characteristics.js");
require("./app/profile-edit/dialog-availability.js");
require("./app/profile-edit/description.js");
require("./app/profile-edit/driving-license-car.js");
require("./app/profile-edit/languages.js");
require("./app/profile-edit/profile_field_update.js");
require("./app/profile-edit/properties.js");
require("./app/profile-edit/special-needs.js");
require("./app/profile-edit/birthdate.js");
require("./app/profile-wizard/sitter-wizard.js");
require("./app/profile-wizard/parent-wizard.js");
require("./app/footer.js");
require("./app/footer-fixed.js");
require("./app/form_validation");
require("./app/select2.js");
require("./app/slider.js");
require("./app/auth/auth.js");
require("./app/auth/signup_complete_form.js");
require("./app/rating.js");
require("./app/clipboard.js");
require("./app/share.js");
require("./app/time_format.js");
require("./app/header.js");
require("./app/header-search.js");
require("./app/homepage_climate_carousel.js");
require("./app/homepage_swap_text.js");
require("./app/homepage-header.js");
require("./app/how_it_works_stepper.js");
require("./app/premium_upgrade.js");
require("./app/premium_review.js");
require("./app/contact.js");

require("./work/admin/member.js");
require("./work/admin/settings.js");

// start the Stimulus application
import "./bootstrap_stimulus.js";

document.addEventListener("DOMContentLoaded", () => {
    autosize(document.querySelectorAll("textarea"));

    const formWithValidation = document.querySelector(
        ".forbidden-words-validation",
    );

    if (formWithValidation) {
        formWithValidation.addEventListener("keyup", (e) => {
            const content = e.target.value;

            e.target.value = content.replace(/http:\/\/|https:\/\//gi, "");
        });
    }

    const showWrapperCreateBooking = document.querySelectorAll(
        ".show-wrapper-create-booking",
    );
    showWrapperCreateBooking.forEach((element) => {
        element.addEventListener("click", (e) => {
            const wrapperCreateBooking = document.querySelector(
                ".wrapper-create-booking",
            );

            wrapperCreateBooking.classList.add("active");
        });
    });

    const hideWrapperCreateBooking = document.querySelectorAll(
        ".hide-wrapper-create-booking",
    );
    hideWrapperCreateBooking.forEach((element) => {
        element.addEventListener("click", (e) => {
            const wrapperCreateBooking = document.querySelector(
                ".wrapper-create-booking",
            );

            wrapperCreateBooking.classList.remove("active");
        });
    });

    const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        new Tooltip(tooltipTriggerEl);
    });

    const verificationsExpandable = document.querySelectorAll(
        ".verifications .verification .title.expandable",
    );

    verificationsExpandable.forEach(function (verificationExpandable) {
        verificationExpandable.addEventListener("click", function () {
            var content = this.closest(".content");
            var description = content.querySelector(".description");

            description.classList.toggle("active");
        });
    });

    const viewPasswords = document.querySelectorAll(".view-password");
    viewPasswords.forEach(function (viewPassword) {
        viewPassword.addEventListener("click", (e) => {
            var passwordGroup = viewPassword.closest(".password-group");
            var password = passwordGroup.querySelector("input");
            var passwordIcon = passwordGroup.querySelector(".password-icon");

            if (password.type === "password") {
                password.type = "text";

                passwordIcon.classList.add("fa-eye");
                passwordIcon.classList.remove("fa-eye-slash");
            } else {
                password.type = "password";

                passwordIcon.classList.add("fa-eye-slash");
                passwordIcon.classList.remove("fa-eye");
            }
        });
    });

    const pricingExpand = document.querySelector(".pricing-expand");
    if (pricingExpand) {
        pricingExpand.addEventListener("click", (e) => {
            var box = document.querySelector(".pricing-expandable-content");
            box.classList.toggle("active");
        });
    }

    const pricingAgenciesExpand = document.querySelector(
        ".pricing-agencies-expand",
    );
    if (pricingAgenciesExpand) {
        pricingAgenciesExpand.addEventListener("click", (e) => {
            var box = document.querySelector(
                ".pricing-agencies-expandable-content",
            );
            box.classList.toggle("active");
        });
    }
});
